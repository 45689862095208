import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Result, PlayingQuiz, QuizDetails, Search, QuizCategory } from './lazy-load';
import DisableBackButton from '../diasble-back-button';

const Quiz = (props) => (
    <Switch>
        <Route path={`${props.match.path}/details/:id`} render={(props) => (
            <QuizDetails key={props.match.params.id} {...props} />)
        } />
        <Route path={`${props.match.path}/category`} component={QuizCategory}/>
        <Route path={`${props.match.path}/result/:id`} component={Result}/>
        <Route path={`${props.match.path}/search`} component={Search}/>
        <Route render={()=>(<Redirect to="/" />)} />
    </Switch>
);

export default withRouter(Quiz);