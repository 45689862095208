export const isLoggedIn = () => {
    const jwt = localStorage.getItem('JWT');
    if (jwt) {
        return true;
    } else {
        return false;
    }
}

export const loggedInUserDetails = () => {
    return JSON.parse(localStorage.getItem('user_details'));
}

//for splashscreen showing store it

export const loggedInUserDetailsSplash = () => {
    return JSON.parse(localStorage.getItem('userDetailsSplash'));
}

export const selectedLanguage = () => {
    var name = 'googtrans';
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2].split('/')[2];
    return 'en';
}

