import React from 'react';
import logo from './logo.png';
import './landingLoader.css';

const LandingLoader = () => (
    <div className="landing-loader">
        <img src={logo} />
    </div>
);

export default LandingLoader;