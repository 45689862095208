import React, {Fragment} from 'react';
import ContentLoader from 'react-content-loader';

export const ProfileDetailsSimmer = (props) => (
  <ContentLoader
    height={250}
    primaryColor="#c3c8caa8"
    secondaryColor="#ffffff"
    speed={2}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" height="50" width="100%" />
    <rect x="15" y="60" rx="100%" ry="100%" height="150" width="150" />
    <rect x="50" y="70" rx="8" ry="8" height="35" width="85%" />
    <rect x="50" y="120" rx="8" ry="8" height="35" width="85%" />
    <rect x="50" y="165" rx="8" ry="8" height="35" width="85%" />
  </ContentLoader>
);

export const TitleSimmer = (props) => (
  <ContentLoader
    height={30}
    primaryColor="#c3c8caa8"
    secondaryColor="#ffffff"
    speed={1}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" height="30" width="220" />
  </ContentLoader>
);

export const DetailsBannerSimmer = (props) => (
  <ContentLoader
    height={180}
    primaryColor="#c3c8caa8"
    secondaryColor="#ffffff"
    speed={2}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" height="180" width="100%" />
  </ContentLoader>
);

export const DetailsSimmer = (props) => (
  <ContentLoader
    height={250}
    primaryColor="#c3c8caa8"
    secondaryColor="#ffffff"
    speed={2}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="25" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="50" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="75" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="100" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="125" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="150" rx="4" ry="4" height="15" width="100%" />
    <rect x="0" y="175" rx="4" ry="4" height="15" width="60%" />
  </ContentLoader>
);

export const AllLeaderBoardSimmer = (props) => (
  <Fragment>
    <LeaderBoardSimmer />
    <LeaderBoardSimmer />
    <LeaderBoardSimmer />
    <ContentLoader 
      height={50}
      primaryColor = "#c3c8caa8" 
      secondaryColor = "#ffffff"
      speed={2}
      {...props}
    >
      <rect x="320" y="5" rx="15" ry="15" height="36" width="60"/>
    </ContentLoader>
  </Fragment>
);
// Quiz Categories By group
export const LeaderBoardSimmer = (props) => ( 
  <ContentLoader 
    height={250}
    primaryColor = "#c3c8caa8" 
    secondaryColor = "#ffffff"
    speed={2}
    {...props}
  >
      <rect x="15" y="5" rx="4" ry="4" height="20" width="60"/>
      <rect x="80" y="8" rx="4" ry="4" height="15" width="120"/>
      <rect x="330" y="5" rx="4" ry="4" height="20" width="50"/>

      <rect x="15" y="40" rx="4" ry="4" height="15" width="64"/>
      <rect x="80" y="40" rx="4" ry="4" height="15" width="249"/>
      <rect x="330" y="40" rx="4" ry="4" height="15" width="50"/>

      <rect x="15" y="60" rx="4" ry="4" height="60" width="365"/>
      <rect x="15" y="121" rx="4" ry="4" height="60" width="365"/>
      <rect x="15" y="182" rx="4" ry="4" height="60" width="365"/>
  </ContentLoader>
);



export const QuizBannerLoader = (props) => ( 
  <ContentLoader 
    height={220}
    primaryColor = "#c3c8caa8"
    secondaryColor = "#ffffff"
    speed={2}
    {...props}
  >
    <rect fill="#000" x="0" y="5" rx="4" ry="4" height="220" style={{width: '100%'}}/>
  </ContentLoader>
);

// Quiz Categories By group
export const RewardBannerLoader = (props) => ( 
  <ContentLoader 
    height={250}
    primaryColor = "#c3c8caa8"
    secondaryColor = "#ffffff"
    speed={2}
    {...props}
  >
    <rect x="0" y="5" rx="4" ry="4" height="200" style={{width: '100%'}}/>
    <rect x="30%" y="220" rx="2" ry="2" height="5" style={{width: '30px'}}/>
    <rect x="40%" y="220" rx="2" ry="2" height="5" style={{width: '30px'}}/>
    <rect x="50%" y="220" rx="2" ry="2" height="5" style={{width: '30px'}}/>
    <rect x="60%" y="220" rx="2" ry="2" height="5" style={{width: '30px'}}/>
  </ContentLoader>
);

// Quiz Categories By group
export const RewardLoader = () => ( 
  <ContentLoader height={220}>
    <rect x="0" y="5" rx="5" ry="5" height="150" style={{width: '30%'}}/>
    <rect x="35%" y="5" rx="5" ry="5" height="150" style={{width: '30%'}}/>
    <rect x="70%" y="5" rx="5" ry="5" height="150" style={{width: '30%'}}/>
    <rect x="0" y="160" rx="2" ry="2" height="20" style={{width: '30%'}}/>
    <rect x="35%" y="160" rx="2" ry="2" height="20" style={{width: '30%'}}/>
    <rect x="70%" y="160" rx="2" ry="2" height="20" style={{width: '30%'}}/>
    <rect x="0" y="182" rx="0" ry="0" height="30" style={{width: '30%'}}/>
    <rect x="35%" y="182" rx="0" ry="0" height="30" style={{width: '30%'}}/>
    <rect x="70%" y="182" rx="0" ry="0" height="30" style={{width: '30%'}}/>
  </ContentLoader>
);
// Quiz Categories By group
export const PopularQuizLoader = () => ( 
  <ContentLoader height={410}>
    <rect x="20" y="5" rx="5" ry="5" height="150" style={{width: '40%'}}/>
    <rect x="220" y="5" rx="5" ry="5" height="150" style={{width: '40%'}}/>
    <rect x="20" y="160" rx="2" ry="2" height="25" style={{width: '40%'}}/>
    <rect x="220" y="160" rx="2" ry="2" height="25" style={{width: '40%'}}/>
    <rect x="20" y="225" rx="5" ry="5" height="150" style={{width: '40%'}}/>
    <rect x="220" y="225" rx="5" ry="5" height="150" style={{width: '40%'}}/>
    <rect x="20" y="380" rx="2" ry="2" height="25" style={{width: '40%'}}/>
    <rect x="220" y="380" rx="2" ry="2" height="25" style={{width: '40%'}}/>
  </ContentLoader>
);

// Quiz Categories By group
export const LatestQuizLoader = () => ( 
  <ContentLoader height={200}>
    {/* <rect x="0" y="5" rx="4" ry="4" height="50" style={{width: '100%'}}/> */}
    <rect x="20" y="5" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="150" y="5" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="280" y="5" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="20" y="125" rx="2" ry="2" height="20" style={{width: '25%'}}/>
    <rect x="150" y="125" rx="2" ry="2" height="20" style={{width: '25%'}}/>
    <rect x="280" y="125" rx="2" ry="2" height="20" style={{width: '25%'}}/>
  </ContentLoader>
);

// Quiz Categories By group
export const CategoryGroupLoader = () => (
  <ContentLoader height={200}>
    <rect x="0" y="5" rx="4" ry="4" height="50" style={{width: '100%'}}/>
    <rect x="20" y="60" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="150" y="60" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="280" y="60" rx="5" ry="5" height="110" style={{width: '25%'}}/>
    <rect x="20" y="180" rx="2" ry="2" height="20" style={{width: '25%'}}/>
    <rect x="150" y="180" rx="2" ry="2" height="20" style={{width: '25%'}}/>
    <rect x="280" y="180" rx="2" ry="2" height="20" style={{width: '25%'}}/>
  </ContentLoader>
);

// Quiz Categories By group
export const AllCategoryGroupLoader = () => (
  <Fragment>
      <CategoryGroupLoader />
      <CategoryGroupLoader />
      <CategoryGroupLoader />
  </Fragment>
);


export const QuizLoader = () => (
  <ContentLoader height={70} style={{borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, inset rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, inset rgba(0, 0, 0, 0.12) 0px 0px 2px 0px'}}>
    <rect x="20" y="10" rx="5" ry="5" height="50" width="50" />
    <rect x="90" y="20" rx="5" ry="5" height="30" width="180"/>
    <rect x="320" y="20" rx="5" ry="5" height="30" width="60"/>
  </ContentLoader>
);

export const AllQuizLoader = () => (
  <Fragment>
      <QuizLoader />
      <QuizLoader />
      <QuizLoader />
      <QuizLoader />
      <QuizLoader />
  </Fragment>
);
