import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
class DisableBackButton extends Component {
    constructor(props) {
        super(props);
        if (window.performance) {
            if (window.performance.navigation.type == 1) {
                // window.location.href="/";
            }
        }
    }
    componentWillMount() {
       history.pushState(null, null, this.props.location.href);
       
       const _self = this;
       window.onpopstate = function (event) {
           history.go(1);
           console.log('exit quiz');
           Swal({
               title: 'Are you sure to quit quiz?',
               type: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Quit',
               cancelButtonText: 'Continue'
           }).then((result) => {
               if (result.value) {
                    _self.props.history.replace('/');
                   // window.location.href="/";
               }
           })
       };
    }
    componentWillUnmount() {
        window.onpopstate = null;
    }
    render() {
        return(''); 
    }
};
export default withRouter(DisableBackButton);