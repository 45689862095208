import { FETCH_PRIVACY_POLICY } from "../types";

const initialState = [];

export const privacyPolicyReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRIVACY_POLICY:
            return action.payload;
        default:
            return state;
    }
};