
import { FETCH_QUESTION_LIST, SELECTED_QUESTION, ADD_PLAYED_QUIZ, RESET_PLAYED_QUIZ } from "../types";

const initialState = {
    all: [],
    played: [],
    selected: 1
};

export const questionReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_QUESTION_LIST:
            return {
                ...state,
                all: action.payload
            }
        case SELECTED_QUESTION:
            return {
                ...state,
                selected: action.payload
            }
        case ADD_PLAYED_QUIZ: 
            return {
                ...state,
                played:[...state.played, action.payload]
            }
        case RESET_PLAYED_QUIZ:
            return {
                ...state,
                played: initialState.played
            }
        default:
            return state;
    }
};

