import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { connect } from 'react-redux';
import { fetchRewardsBanner } from '../../store/actions';
import './slide-show.css';
import { post } from '../../http-api'; 
import { RewardBannerLoader } from '../../content-loader';
import { BASE_URL_IMAGE } from '../../http-api';
function shuffleArray(array){
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }
class SlideShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        } 
    }
    componentDidMount() {
        post('rewards_banner')
        .then(res=>{
            if(res.data.success) {
                const {banner_rwd_details} = res.data;
                console.log({banner_rwd_details});
                this.props.fetchRewardsBanner(banner_rwd_details);
                this.setState({loading: false});
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({loading: false});
            throw new Error('Could not fetch products. Try again later.');
        });
    }
     
    render() {
        const { rewardBanners } = this.props;
        const { loading } = this.state;
        console.log(rewardBanners);
        const suffleRewardBanners = shuffleArray(rewardBanners);
        if (loading)
            return(<RewardBannerLoader />);
        const banners = suffleRewardBanners.slice(0,3).map((banner, key) => (
            <div key={key} className="item" style={{position: 'relative'}}>
                <Link to={`/rewards/details/${banner.reward_id}`}>
                    <img height="200" src={BASE_URL_IMAGE+banner.banner_image} />
                </Link>
                <div style={{position: 'absolute', zIndex: '1000', left: 0, bottom: 0, right: 0, width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', textAlign: 'center', height: 'auto', padding: '10px', color: '#fff', pointerEvents: 'none'}}>
                {banner.description}</div>
            </div>
        )); 
        return(
            <OwlCarousel
                className="owl-theme"
                loop={true}
                items={1}
                margin={10}
                lazyLoad={true}
                lazyContent={true}
                autoplay={true}
            >
                {banners}
            </OwlCarousel>
        );
    }
};
const mapStateToProps = (state) => ({
    rewardBanners: state.rewards.banner,
});

const mapActionsToProps = {
    fetchRewardsBanner: fetchRewardsBanner
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(SlideShow));


