import { FETCH_TERM_SERVICE } from "../types";

const initialState = [];

export const termServiceReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_TERM_SERVICE:
            return action.payload;
        default:
            return state;
    }
};