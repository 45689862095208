import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, MemoryRouter} from "react-router-dom";

import Routes from './containers';
import './index.css';
import { Provider } from 'react-redux';
import { store } from "./store";
import LandingLoader from './loader/landingLoader';
const Index = () => {
  return(
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render( <Index /> , document.getElementById("index"));