import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderWithBackButton from './header-with-back-button';
import Search from './search';

class TemplateWithGoBackButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSearchBox: false
        }
    }
    openSearchBox = () => {
        this.setState({openSearchBox: true});
    }
    closeSearchBox = () => {
        this.setState({openSearchBox: false});
    }
    render() {
        const {children} = this.props;
        const {openSearchBox} = this.state;
        return(
            <Fragment>
                <HeaderWithBackButton openSearchBox={this.openSearchBox}/>
                <Search open={openSearchBox} closeSearchBox={this.closeSearchBox} />
                <div 
                    id="pageContent" 
                    className="page-content page-content-bg-color" 
                    style={{
                        height: '100vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >{children}</div>
            </Fragment>
        );
    }
}
export default withRouter(TemplateWithGoBackButton);