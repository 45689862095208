import Loadable from 'react-loadable';
import LandingLoader from '../../loader/landingLoader';

export const QuizDetails = Loadable({
    loader: () => import( /* webpackChunkName: "quizDetails" */ './quiz-details'),
    loading: LandingLoader
});


export const QuizCategory = Loadable({
    loader: () => import( /* webpackChunkName: "category" */ './category'),
    loading: LandingLoader
});

export const PlayingQuiz = Loadable({
    loader: () => import( /* webpackChunkName: "playQuiz" */ './playing-quiz'),
    loading: LandingLoader
});

export const Result = Loadable({
    loader: () => import( /* webpackChunkName: "result" */ './result'),
    loading: LandingLoader
});


export const Search = Loadable({
    loader: () => import( /* webpackChunkName: "search" */ './search'),
    loading: LandingLoader
});