import Loadable from 'react-loadable';
import Loader from '../../components/loader/spiner';

// Profile Page
export const Profile = Loadable({
    loader: () => import( /* webpackChunkName: "profileD" */ './details'),
    loading: Loader
});


// Profile Edit Page
export const Edit = Loadable({
    loader: () => import( /* webpackChunkName: "profileEdit" */ './components/edit'),
    loading: Loader
});