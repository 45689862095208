import React, { Component } from 'react';
import Ionicon from 'react-ionicons';
import { Link, withRouter } from 'react-router-dom';
import TemplateWithGoBackButton from '../template/template-with-goback-button';

class Language extends Component {
    language = (lan) => {
        document.cookie = `googtrans=/en/${lan}`;
        document.cookie = 'googtrans=; path=/; domain=.quizy.mobi; expires=' + new Date(0).toUTCString();
        window.location.href = '/lang';
    }
    checkSelectedLanguage = () => {
        var name = 'googtrans';
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2].split('/')[2];
        return 'en';
    }
    render() { 
        const { location } = this.props;
        const from = location.state ? location.state.from : '/';
        const MarkedLanguage = (<Ionicon icon="md-checkmark" fontSize="2em" color="#69dc14" style={{position: 'absolute', right: '10px', top: 0, bottom: 0}}/>);
        return(
           <TemplateWithGoBackButton>

                <div class="blocklist" style={{height: '100vh', position: 'fixed', width: '100vw', background: '#fff', top: '44px'}}>

                   <div class="item-list pt-0" style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)', marginTop: '10px'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('th')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>Thai</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='th'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>

                    <div class="item-list " style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)', marginTop: '10px'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('en')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>English</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='en'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>


                    <div class="item-list"  style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('id')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>Indonesia</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='id'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>
                    <div class="item-list" style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('ms')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>Malaysia</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='ms'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>
                    <div class="item-list" style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('nl')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>Deutch</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='nl'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>
                    <div class="item-list" style={{borderColor: '#fff', borderBottom: '1px solid rgba(173, 171, 171, 0.5)'}}>
                        <a class="item-content notranslate" onClick={()=>this.language('ar')} style={{padding: '15px 0px'}}>
                            <div class="col-xs-6 title text-left" style={{textAlign: 'left'}}>Arabic</div>
                            <div class="col-xs-6 text-right">
                                {this.checkSelectedLanguage()==='ar'? MarkedLanguage: ' '}
                            </div>
                        </a>
                    </div>
                </div>
               </TemplateWithGoBackButton> 
        ); 
    }
};

export default withRouter(Language);