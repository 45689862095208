import Loadable from 'react-loadable';
import LandingLoader from '../../loader/landingLoader';

export const LeaderBoardDetails = Loadable({
    loader: () => import( /* webpackChunkName: "leaderboard-details" */ './leaderboard-details'),
    loading: LandingLoader
});

export const LeaderBoardAll = Loadable({
    loader: () => import( /* webpackChunkName: "leaderboard-all" */ './leaderboard-all'),
    loading: LandingLoader
});

