import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Ionicon from 'react-ionicons';
// import { isLoggedIn, loggedInUserDetails } from '../../helper/authentication';
// import logo from '../../assets/img/logo.png';
// import SideMenuBar from './side-menu-bar';
// import "./sidebar.css";
// import SideBar from "./sidebar";
import {BASE_URL_REACT_QUIZY_LOGO} from '../../http-api';
class Header extends Component {
    constructor() {
        super();
        this.state = {
            sideMenuBar: false
        };
    }
    openSideMenuBar = () => {
        this.setState(prevState => ({
            sideMenuBar: true
        }));
        //document.body.style.position = "fixed";
    }
    closeSideMenuBar = () => {
        this.setState(prevState => ({
            sideMenuBar: false
        }));
        //document.body.style.position = "static";
    }
    render() {
        const back_link = (
            <Link to="/" className="link back">
                <Ionicon icon="md-arrow-back" fontSize="1em" color="#fff" />
			</Link>
        );

        const menu_btn = (
            <button onClick={this.openSideMenuBar} type="button" className="button-collapse navbar-toggle nav-toggle">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar white"></span>
                <span className="icon-bar white"></span>
            </button>
        );

        const { sideMenuBar } = this.state;

        let location = window.location.href;
        location = location.split('/');
        location = location[3];
        location = location.split('#');
        location = location[0];
        location = location.split('?');
        location = location[0];
        const fruits = ["login", "register", "terms-of-service", "privacy-policy", "frequently-asked-questions"];

        return(
            <React.Fragment>
                <div id="toolbar" className="bluedark toporange botorange">
                    <div className="open-left">
                        {/* { fruits.indexOf(location) !== -1 ? back_link : menu_btn } */}
                    </div>
                    <div className="logo m-0">
                        <Link to='/'>
                            <img src={BASE_URL_REACT_QUIZY_LOGO} alt="" style={{height: '42px'}}/>
                        </Link>
                    </div>
                    <div className="open-right" data-activates="slide-out-right">
                        <a onClick={()=>this.props.openSearchBox()} seach="true" className="ion-head">
                            <Ionicon icon="md-search" fontSize="1em" color="#fff" />
                        </a>
                    </div>
                </div>
                <div className="clearfix"></div>
                {/* <SideBar pageWrapId={"page-content"} outerContainerId={"index"} /> */}
                {/* <SideMenuBar open={sideMenuBar} closeSideMenuBar={this.closeSideMenuBar}/> */}
            </React.Fragment>
        );
    }
};

export default Header;


const CustomSideBarLink = (props) => {
    return(
        <Link to={props.path} className="item-link close-panel item-content" onClick={props.closeSideMenuBar}>
            {props.children}
        </Link>
    );
}
