import React, { Component, Fragment } from "react";
import "./change-password.css";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      nameError:"",
      passwordError:"",
    };
  }
  valid()
  {
    if (
        !this.state.name.includes("@") &&
        this.state.password.length < 5
      ) {
        this.setState({
          nameError: "Invalid email",
          passwordError: "Password length should be more than 5 character",
        });
 
        }
        if (!this.state.name.includes("@") ) {
            this.setState({
              nameError: "Invalid email",
              
            });
     
            }
            if (this.state.password.length < 5) {
                this.setState({
                  
                  passwordError: "Password length should be more than 5 character",
                });
         
                }
        else
        return true;
}
  submit()
  {
      console.log(this.state.name,this.state.password);
      if(this.valid())
      {
      alert("Form has been submitted");
      }
  }

  render() {
    return (
      //in the return add your design html and css
      <Fragment>
        <div class="loginbox">
          {/*<img src="image/a.jpg" class="avatar" />*/}
          <h1>Login Here</h1>
          <form>
            <p>
              <i class="fa fa-user icon"></i>&nbsp;Username
            </p>

            <input
              type="text"
              name=""
              placeholder="Enter Username test1"
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <p>{this.state.nameError}</p>
            <p>
              <i class="fa fa-key icon"></i>&nbsp;Password
            </p>
            <input
              type="password"
              name=""
              placeholder="Enter Your Password"
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
            />
            <p>{this.state.passwordError}</p>
            <input
              type="submit"
              name=""
              value="Ubah Password"
              class="xyz"
              onClick={() => this.submit()}
            />
            <input type="submit" name="" value="masuk" class="abc" />
            <br />
            <a href="#">Lost your Password?</a>
            <br />
            <a href="#">Don't have an account?</a>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default ChangePassword;
