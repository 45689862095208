import React, { Component, Fragment } from 'react';
import profile_img from '../../assets/img/acc-default.png';
import { isLoggedIn, loggedInUserDetails } from '../../helper/authentication';
import { Link, withRouter } from 'react-router-dom';
import gold_coin from '../../assets/gold_coin.png';
import Avatar from 'react-avatar';
import { post_loggedInUser, BASE_URL_PROFILE } from '../../http-api';
class Account extends Component {
    componentDidMount() {
        //   post_loggedInUser('user_details')
        //   .then(res => {
        //         const {eachuserdetails} = res.data;
        //         localStorage.setItem('user_details', JSON.stringify(eachuserdetails));
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         throw new Error('Could not fetch products. Try again later.');
        //     });
    }
    render() {
    const userDetails = loggedInUserDetails();
    return(
        <Fragment>
                {
                    userDetails?
                        <Link onClick={this.props.closeMenu} to="/profile" style={{position: 'relative', display: 'block', padding: '20px 0px 8px 8px'}}>
                            <div>
                                <Avatar style={{float: 'left'}}
                                    round={true} 
                                    size="60" 
                                    src={userDetails.image ? `${BASE_URL_PROFILE}${userDetails.image}` : null} 
                                    name={`${userDetails.first_name} ${userDetails.last_name}`}
                                />
                                <p style={{padding: '25px 0 0 10px', display: 'inline-block', color: '#fff'}}>{userDetails.first_name} {userDetails.last_name}</p>
                            </div> 
                                <div style={{position: 'absolute', right: '50px', top: '0px', zIndex: 1000}}>
                                    <img src={gold_coin} style={{height: '40px', position: 'relative', zIndex: 1}}/>
                                    <span class="badge" style={{
                                            fontSize: '14px', 
                                            marginLeft: '-8px',
                                            padding: '5px 7px 5px 8px',
                                            borderRadius:'5px', 
                                            backgroundColor: '#FF4E1A'
                                    }}>{userDetails.coin}</span>
                                </div>
                        </Link>
                    :
                        <a onClick={this.props.closeMenu} style={{position: 'relative', display: 'block', padding: '20px 0px 8px 8px'}}>
                            <div>
                                <Avatar style={{float: 'left'}}
                                    round={true} 
                                    size="60" 
                                    src={profile_img} 
                                    name="Demo Azik"
                                />
                                <p style={{padding: '25px 0 0 10px', display: 'inline-block', color: '#fff'}}>Demo Quizy</p>
                            </div> 
                        </a>
                }
        </Fragment>
    );
    }
};

export default Account;