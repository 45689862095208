import React, { Component, Fragment } from "react";
import { Link, withRouter } from 'react-router-dom';
import { stack as Menu } from "react-burger-menu";
import { isLoggedIn } from '../../helper/authentication';
import Account from './account';
import {selectedLanguage} from '../../helper/authentication';
class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = (state) => {
        this.setState({
            menuOpen: state.isOpen
        })
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({
            menuOpen: false
        })
    }
    
    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    logout = () => {
        localStorage.removeItem('JWT');
        localStorage.removeItem('user_details');
        window.location.href = "/";
    }
    render(){
        const language = { "en": "English", "id": "Indonesia", "ms": "Malaysia", "nl": "Deutch" ,"ar":"Arabic"};
        const date = new Date();
        return (
            <Menu {...this.props}
                isOpen={this.state.menuOpen} 
                onStateChange={(state) => this.handleStateChange(state)}
            >
                <Account closeMenu={this.closeMenu} />
                <a className="menu-item menu-divider">Menu</a>
                <Link onClick={() => this.closeMenu()} to="/" className="menu-item indivdual-item-menu">
                    <i className="fa fa-home"></i><div className="menu-item-div">Home</div>
                </Link>
                {!isLoggedIn() && <Link onClick={() => this.closeMenu()} to="/login" className="menu-item indivdual-item-menu">
                    <i className="fa fa-sign-in-alt"></i><div className="menu-item-div">Login</div>
                </Link>}
                <Link onClick={() => this.closeMenu()} to="/quiz/category" className="menu-item indivdual-item-menu">
                    <i className="fa fa-bookmark"></i><div className="menu-item-div">Quiz Category</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/rewards" className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Rewards</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/leaderboard/all" className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Leader Board</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to={{pathname:"/lang", state: { from: this.props.location.pathname} }} className="menu-item indivdual-item-menu">
                    <i className="fa fa-globe"></i><div className="menu-item-div">Language</div>
                    <div style={{float: 'right', marginRight: '40px'}}>{language[selectedLanguage()]}</div>
                </Link>
                {isLoggedIn() && <a onClick={this.logout} className="menu-item indivdual-item-menu">
                                    <i className="fa fa-sign-out-alt"></i><div className="menu-item-div">Logout</div>
                                </a>}
                <a className="menu-item menu-divider"></a>
                <Link onClick={() => this.closeMenu()} to="/frequently-asked-questions" className="menu-item indivdual-item-menu">
                    <i className="fa fa-question-circle"></i><div className="menu-item-div">FAQ</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/privacy-policy" className="menu-item indivdual-item-menu">
                    <i className="fa fa-question-circle"></i><div className="menu-item-div">Privacy Policy</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/terms-of-service" className="menu-item indivdual-item-menu">
                    <i className="fa fa-question-circle"></i><div className="menu-item-div">Terms of Service</div>
                </Link>
                {/* <a className="menu-item menu-divider"></a>
                <div className="follow">
                    <FollowInSocialMedia />
                </div>
                <div class="text-center text-small text-grey mt-5" style={{textAlign: 'center', fontSize: '12px'}}>@ {date.getFullYear()} Quizy</div> */}
            </Menu>
        );
    }
};

export default withRouter(SideBar);

const FollowInSocialMedia = () => (
    <Fragment>
        <div className="footer-text">Follow us</div>
        <div className="social-media-icons">
            <ul>
                <li>
                    <a href="https://www.facebook.com/" target="_blank" className="medium-fb">
                        <span className="social-icon icon-fb"><i className="fab fa-facebook-square"></i></span>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com" target="_blank" className="medium-twitter">
                        <span className="social-icon icon-twitter"><i className="fab fa-twitter"></i></span>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/" target="_blank" className="medium-youtube">
                        <span className="social-icon icon-youtube"><i className="fab fa-youtube"></i></span>
                    </a>
                </li>
            </ul>
        </div>
    </Fragment>
);
