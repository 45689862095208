import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import Ionicon from 'react-ionicons';
import classNames from 'classnames';
import {DebounceInput} from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { post } from '../http-api';
import './search.css';
import noSearchDataFound from '../assets/sorry_no_data_found.png';
import { setSearchKey, setSearchResult} from '../store/actions';
import {BASE_URL_IMAGE} from '../http-api';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFound: true
        }
    }

    onClickSearch = (search) => {
        this.props.setSearchKey(search);
        if(search !== '') {
            const payload = new FormData(); 
            payload.append("title", search);
            post('searchcategory', payload)
            .then(res => {
                console.log(res);
                if(res.data.success) {
                    const { search_details } = res.data;
                    this.props.setSearchResult(search_details);
                    this.setState({dataFound: true});
                }
                if(res.data.error) {
                    this.props.setSearchResult([]);
                    this.setState({dataFound: false});   
                }
            })
            .catch(err => {
                console.log(err);
                throw new Error('Could not fetch products. Try again later.');
            });
        } else {
            this.props.setSearchResult([]);
        }
    }

    render() { 
        const classes = ["quiz-history-bg-color-1", "quiz-history-bg-color-2"];
        const {open} = this.props;
        const { dataFound } = this.state;
        const { searchKey, searchResult } = this.props;
        return(
            <div className={classNames("search", {"search-active":open})}>
                <div id="toolbar" className={classNames("toporange","search-app-bar","top-m-60", {"top-0": open})}>
                    <div className="open-right">
                        <Ionicon 
                            onClick={()=>this.props.closeSearchBox()}
                            className="ion-head"
                            icon="md-arrow-back"
                            fontSize="1em"
                            color="#959595" 
                        />
                    </div> 
                    <div style={{margin: 0, width: '80%'}}>
                            <DebounceInput
                                id="Search"
                                type="search"
                                value={searchKey}
                                minLength={1}
                                debounceTimeout={500}
                                onChange={event => this.onClickSearch(event.target.value)}
                                placeholder="Search category..." 
                            />
                    </div>
                </div>
                <div 
                    className="page-content page-content-bg-color" 
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        padding: '10px',
                        marginTop: '60px'
                    }}
                >
                    {searchResult && searchResult.map((value, key) => (
                        <Link 
                            key={key} 
                            onClick={()=>this.props.closeSearchBox()} 
                            to={`/quiz/details/${value.id}`} 
                            className={classNames(classes[key % 2], 'quiz-history')}
                        >
                            <div className="col-xs-3">
                                <img 
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        objectFit: 'cover',objectPosition: 'center'
                                        }} 
                                    src={BASE_URL_IMAGE+value.title_image} alt={value.title_image}/> 
                            </div>
                            <div className="col-xs-9 pd-0">
                                <div 
                                    className="text-hstry" 
                                    style={{
                                        lineHeight: '24px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                        textTransform: 'capitalize'
                                        }}
                                >{value.title}</div>
                                <div 
                                    style={{
                                        color: '#333',
                                        lineHeight: '20px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%'
                                    }}
                                >{value.total_question} Questions</div>
                            </div>
                        </Link>
                    ))
                    }
                    {
                        !dataFound && <div className="no-data-found" style={{backgroundImage: `url(${noSearchDataFound})`}}>No Data Found!</div>
                    }
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    searchResult: state.search.searchResult,
    searchKey: state.search.searchKey
});

const mapActionsToProps = {
    setSearchKey: setSearchKey,
    setSearchResult: setSearchResult
};

export default connect(mapStateToProps, mapActionsToProps)(Search);