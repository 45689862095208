import { FETCH_FAQ } from "../types";

const initialState = [];

export const faqReducecer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_FAQ:
            return action.payload;
        default:
            return state;
    }
};