import React from 'react';
import { isLoggedIn, loggedInUserDetails } from '../../../helper/authentication';
import gold_coin from '../../../assets/gold_coin.png';
const MyCoin = () => {
    if(isLoggedIn()) {
        const userDetails = loggedInUserDetails();
        return(
            <div style={
                {
                    padding: '1px 20px 2px 20px',
                    fontSize: '19px',
                    backgroundColor: 'ghostwhite',
                    boxShadow: '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
                    borderTop: '0.1px solid grey',
                    overflow: 'auto',
                    lineHeight: '45px'
                }
            }>My Coin
            <div style={{float: 'right'}}>
                <img src={gold_coin} style={{height: '45px', position: 'relative', zIndex: 1}}/>
				<span class="badge" style={
                    {
                        fontSize: '14px', 
                        marginLeft: '-12px',
                        padding: '8px 12px 9px 13px',
                        borderRadius:'5px', 
                        backgroundColor: '#FF4E1A'
                    }
                }>{userDetails.coin}</span>
            </div>
            </div>
        );
    }
    return(null);
}

export default MyCoin;